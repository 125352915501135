import { ref } from "vue";

export default function() {
  const selection = ref([]);
  const handleSelectionChange = (val) => {
    selection.value = val;
  };

  return { selection, handleSelectionChange };
}
