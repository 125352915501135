<template>
  <div style="padding: 5px">
    <div class="flex flex-row items-center justify-between py-1">
      <el-button @click="refreshSelected" type="text">更新所选</el-button>
      <div class="flex flex-row items-center">
        <span class="px-1 text-gray-500 text-sm">办税人员</span>
        <el-select
          v-model="workerPhone"
          filterable
          placeholder="请选择"
          size="small"
          class="w-24"
        >
          <el-option
            v-for="item in users"
            :key="item.uid"
            :label="item.name"
            :value="item.telphone"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table
      :data="tableData"
      :height="tableHeight"
      @selection-change="handleSelectionChange"
      border
      header-row-class-name="text-gray-600"
      cell-class-name="bg-gray-50"
    >
      <el-table-column type="selection" width="30"> </el-table-column>
      <el-table-column label="公司名称" prop="name">
        <template #default="scope">
          <router-link :to="`/company/edit/${scope.row.uid}`">
            <span class="text-blue-400">{{ scope.row.name }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="信用代码" prop="ucc" width="190" />
      <el-table-column label="税务密码" prop="etax_password" width="100" />

      <el-table-column label="申报清册" prop="sbqc_record" width="250">
        <template #default="scope">
          <div class="flex flex-row items-center">
            <i
              v-if="
                scope.row.sbqc_record && scope.row.sbqc_record.content.is_safe
              "
              class="el-icon-check text-green-400 text-xl"
            ></i>
            <i v-else class="el-icon-close text-red-400 text-xl"></i>
            <i
              class="el-icon-refresh text-blue-400 cursor-pointer text-lg px-1"
              @click="() => task(scope.row, 'sbqc')"
            />
            <a
              v-if="scope.row.sbqc_record"
              :href="`/#/fj_tax/sbqc_record/${scope.row.uid}`"
              class="text-blue-400"
              >{{ stamp2time(scope.row.sbqc_record.create_time) }}</a
            >
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="40">
        <template #default="scope">
          <el-button
            @click.prevent="() => task(scope.row, 'login')"
            type="text"
            size="small"
          >
            登录
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";
import { stamp2time } from "@/utils/timeParser.js";
import useTableSelect from "@/composition/useTableSelect.js";

export default defineComponent({
  name: "SBQC",
  setup() {
    const route = useRoute();
    const store = useStore();
    const model = route.meta.model;
    const users = computed(() => store.state.user.all);
    const workerPhone = ref(store.state.user.me.telphone); // 办税人员电话
    const tableData = computed(() => store.state[model].pagination.items);
    const tableHeight = ref(window.innerHeight - 145);
    const { selection, handleSelectionChange } = useTableSelect();

    const handleWindowResize = () => {
      tableHeight.value = window.innerHeight - 145;
    };

    const _checkBeforeLogin = (company) => {
      if (!workerPhone.value) {
        throw "请先选择办税人员";
      }
      if (!company.ucc) {
        throw "公司的统一信用代码不能为空";
      }
      if (!company.etax_password) {
        throw "公司的国税密码不能为空";
      }
    };

    const task = (company, func) => {
      try {
        _checkBeforeLogin(company);
      } catch (message) {
        ElNotification.warning({ message });
        return;
      }
      company["worker_phone_last4"] = workerPhone.value.slice(-4);
      store.dispatch("helper/task", {
        func,
        pkg: "app.fj_tax.api",
        kwargs: { company },
      });
    };

    const refreshSelected = () => {
      if (!selection.value || selection.value.length < 1) {
        ElNotification.warning("请至少选择一行记录");
        return;
      }
      console.log(selection.value);
    };

    onMounted(() => {
      // 筛选出需要报税的公司
      const params = store.state[model].params;
      const filter = {
        status: { operator: "eq", value: true },
        agent_fee: { operator: "gt", value: 0 },
      };
      store.commit(`${model}/updateParams`, { ...params, filter });
      store.dispatch(`${model}/pagination`);

      store.dispatch("user/all");

      window.addEventListener("resize", handleWindowResize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", handleWindowResize);
    });

    return {
      tableData,
      tableHeight,
      task,
      users,
      workerPhone,
      stamp2time,
      refreshSelected,
      handleSelectionChange,
    };
  },
});
</script>
